<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Площадки</h1>
      </div>
      <div class="page-header__ell">
        <router-link :to="{ name: this.$routeName.PLACE_SELECT_TYPE }" class="add-link">
          Добавить площадку
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="applyFilter" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect width="28" height="28" rx="14" fill="#E54C4C"/><path d="M14 6.5v15M21.5 14h-15" stroke="#161616" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </router-link>
      </div>
    </div>
    <div class="page-control">
      <div class="page-control__ell">
        <FveSearchText :value="filterSearch" @search="applyFilter" />
      </div>
    </div>

    <div class="row">
      <template v-for="(place, index) in placeList">
        <div class="col-12 col-md-4 mb-md" :key="index">
          <div class="card-link" v-on:click.prevent.stop="editPlace(place)">
            <CardClub
              :satus="place.satus"
              :name="place.name"
              :club="place.club_name"
              :text="place.address"
            />
          </div>
        </div>
      </template>
    </div>
  
    <b-pagination
      v-if="pageCount > pageLimit"
      v-model="pageCurrent"
      :total-rows="pageCount"
      :per-page="pageLimit"
      @change="paginationChange"
      
      align="right"
      last-class="hidden"
      first-class="hidden"
      first-number
      last-number
    ></b-pagination>
  </div>
</template>

<script>

import FveSearchText from "@widgetFormValidate/src/Element/Search/FveSearchText";
import CardClub from "@component/Module/CardClub";

export default {
  name: "PlaceList",
  components: {
    CardClub,
    FveSearchText,
  },
  data() {
    return {
      filterSearch: '',
      pageCurrent: 1,
      pageLimit: 15,
      pageCount: null,
      placeList: [],
    };
  },
  methods: {
    load(){
      RequestManager.Place.getPlaceList({
        limit   : this.pageLimit,
        page    : this.pageCurrent,
        search  : this.filterSearch !== '' ? this.filterSearch : null,
      }).then( (itemList) => {
        this.pageCount = itemList.count;
        this.placeList = itemList.results.map((item) => {
          return {
            id        : item.id,
            satus     : item.is_active,
            name      : item.name,
            club_name : item.club_name,
            address   : item.address,
            clubId    : item.club,
          };
        });
      });
    },
    deletePlace(place) {
      const options = {title: 'Удалить площадку?', cancelLabel: 'Нет', okLabel: 'Да'};
      this.$dialogs.confirm('Удалить площадку?', options)
        .then((res) => {
          if(res && res.ok) {
            RequestManager.Place.deletePlaceById({id: place.id}).then(() => {
              this.$toasts.push({
                message: 'Площадка успешно удалена',
                duration: 4000,
              });
              this.load();
            }, () => {});
          }
        });
    },
    editPlace(place) {
      if (place.clubId) {
        this.$router.push({ name: this.$routeName.PLACE_CLUB_EDIT, params: { placeId: place.id }});
      } else {
        this.$router.push({ name: this.$routeName.PLACE_SINGLE_EDIT, params: { placeId: place.id }});
      }
    },
    paginationChange(val) {
      this.pageCurrent = val;
      this.load();
    },
    applyFilter(val) {
      this.pageCurrent = 1;
      this.filterSearch = val;
      this.placeList = [];
      this.load();
    },
  },
  created() {
    this.load();
  }
};
</script>

<style lang="scss" scoped>

// btn-control
.btn-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn-control__icon {
    margin-left: 14px;
    color: var(--text-sub);
    cursor: pointer;
    transition: all .2s ease-in-out;
    &:hover {
      color: var(--color-prime);
    }
    &--active {
      color: var(--color-prime);
    }
  }
}

// details-card
::v-deep .b-table-details {
  td {
    background-color: var(--bg-prime);
  }
}
.details-card {
  display: flex;
  padding: 4px 0;
  .details-card__item {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    .details-card__prop {
      margin-bottom: 4px;
      color: var(--text-descr);
      font-size: 14px;
    }
    .details-card__value {
      color: var(--text-sub);
      font-size: 14px;
    }
  }
}

</style>
